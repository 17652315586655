<template>
  <div>
    <h1 class="main-title page-title">Estimations</h1>
  </div>
</template>

<script>
export default {
  name: "Estimations"
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

</style>